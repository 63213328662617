<template>
  <div>
    <div v-if="attributes.length > 0" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title">
              <span class="card-label font-weight-bolder text-dark"
                >All Attributes</span
              >
            </h3>
            <div class="card-toolbar">
              <router-link
                to="/products-attributes/new"
                class="btn btn-mc font-weight-bolder font-size-sm"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  /> </span
                >Add New Attribute
              </router-link>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive">
              <table
                class="table table-head-custom table-head-bg table-vertical-center"
              >
                <thead>
                  <tr class="text-left text-uppercase">
                    <th style="min-width: 120px" class="pl-6">Attribute</th>
                    <th style="min-width: 120px">Root attribute</th>
                    <th style="min-width: 120px">
                      Related to
                    </th>
                    <th style="min-width: 110px" class="pr-6 text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
                <draggable
                  v-model="filteredAttributes"
                  @end="onEnd"
                  tag="tbody"
                  handle=".handle"
                >
                  <tr v-for="(attribute, key) in filteredAttributes" :key="key">
                    <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                      <span v-if="attribute.root.title">-- </span>
                      <span v-else></span>
                      <router-link
                        :to="'/products-attributes/edit/' + attribute.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ attribute.title }}</router-link
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        v-if="attribute.root.title"
                        class="label label-lg label-light-primary label-inline"
                        >{{ attribute.root.title }}</span
                      >
                      <span
                        v-else
                        class="label label-lg label-light-danger label-inline"
                        >Root</span
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        v-if="attribute.productCategory"
                        class="label label-lg label-light-primary label-inline"
                        >{{ attribute.productCategory.title }}</span
                      >
                    </td>
                    <td class="pr-6 text-right">
                      <button
                        v-if="attribute.root.title"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3 handle"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Navigation/Up-down.svg"
                          />
                        </span>
                      </button>
                      <router-link
                        :to="'/products-attributes/edit/' + attribute.id"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Communication/Write.svg"
                          />
                        </span>
                      </router-link>
                      <button
                        @click="removeAttribute(attribute.id)"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg src="media/svg/icons/General/Trash.svg" />
                        </span>
                      </button>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 text-center">
        <b-spinner
          variant="primary"
          type="grow"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import draggable from 'vuedraggable'

export default {
  name: 'ListProductsAttributes',
  components: {
    draggable
  },
  data() {
    return {
      attributes: [],
      parentAttributes: [],
      childrenAttributes: [],
      filteredAttributes: [],
      totalItems: null,
      productCategoryOrder: {
        query: 'asc',
        isActive: false
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Attributes', route: '/products-attributes/list' }
    ])

    ApiService.get(
      `attributes`,
      `?order[createdAt]=desc&order[position]=asc&itemsPerPage=300`
    ).then(({ data }) => {
      this.totalItems = data['hydra:totalItems']
      this.attributes = [...data['hydra:member']]

      this.parentAttributes = this.attributes.filter(
        category => category.parent === undefined
      )
      this.childrenAttributes = this.attributes.filter(
        category => category.parent !== undefined
      )

      this.parentAttributes.forEach(parentCategory => {
        this.filteredAttributes.push(parentCategory)
        this.childrenAttributes.forEach(childrenCategory => {
          if (childrenCategory.root['@id'] === parentCategory.root) {
            this.filteredAttributes.push(childrenCategory)
          }
        })
      })
    })
  },
  methods: {
    async deleteAttribute(id) {
      try {
        return await ApiService.delete('/attributes/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    orderByProductCategory() {
      this.productCategoryOrder.isActive = true

      if (this.productCategoryOrder.query === 'asc')
        this.productCategoryOrder.query = 'desc'
      else this.productCategoryOrder.query = 'asc'

      this.attributes = []

      ApiService.get(
        `attributes`,
        `?order[title]=${this.productCategoryOrder.query}&itemsPerPage=300`
      ).then(({ data }) => {
        this.attributes = [...data['hydra:member']]
        this.totalItems = data['hydra:totalItems']
      })
    },
    removeAttribute(id) {
      const confirmation = confirm('You want delete this attribute?')
      if (confirmation === false) return

      this.deleteAttribute(id).then(({ status }) => {
        if (status === 204) {
          this.attributes = this.attributes.filter(product => product.id !== id)
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Attribute success deleted from database'
          })
        }
      })
    },
    onEnd() {}
  }
}
</script>
